import { render, staticRenderFns } from "./index.vue?vue&type=template&id=12dd99ca&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=12dd99ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12dd99ca",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WBonos from '@/components/WBonos.vue'
import WLoading from '@/components/WLoading.vue'
import WPagination from '@/components/WPagination.vue'
import WPickupConfirmBonos from '@/components/WPickupConfirmBonos.vue'
import WSwitch from '@/components/WSwitch.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WBonos,WLoading,WPagination,WPickupConfirmBonos,WSwitch,WTopBar})
