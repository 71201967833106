<template>
  <div class="flex flex-col" :class="{ 'opacity-50': loading }">
    <div class="-my-2 overflow-x-auto md:overflow-hidden sm:-mx-6 lg:-mx-8">
      <div
        class="inline-block min-w-full py-2 overflow-hidden align-middle sm:px-6 lg:px-8"
      >
        <div
          class="flex flex-col items-center justify-center h-48"
          v-if="items.length === 0 && !loading"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-12 h-12 text-base-300"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="my-2 font-normal text-gray-700">
            No hay bonos a retirar
          </div>
        </div>
        <div
          v-else
          class="overflow-hidden shadow bshipment-b bshipment-gray-200 sm:rounded-lg"
        >
          <table
            class="min-w-full overflow-hidden divide-y divide-gray-200 rounded-lg"
          >
            <thead
              class="hidden md:table-header-group print:table-header-group"
            >
              <tr>
                <th
                  class="py-3 px-6 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Nombre
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase md:w-44 print:hidden md:table-cell bg-gray-50"
                >
                  Bonos
                </th>
                <th
                  class="hidden px-6 py-3 text-xs text-right font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Entrega
                </th>
              </tr>
            </thead>
            <tbody
              class="flex-1 bg-white divide-y divide-gray-300 sm:flex-none print:flex-none"
            >
              <tr
                class="flex flex-col mb-2 flex-no wrap sm:table-row print:table-row sm:mb-0"
                v-for="item in items"
                :key="item._id"
              >
                <td class="flex py-3 overflow-hidden whitespace-no-wrap px-6">
                  <div class="">
                    <!-- <w-food-shipment-address :value="shipment.shipping" /> -->
                    <div class="w-24 md:w-auto print:w-auto">
                      {{ item.nombre }} {{ item.apellido }}
                    </div>
                    <div>
                      <a :href="'mailto:' + item.email" class="text-gray-600">
                        {{ item.email }}
                      </a>
                    </div>
                  </div>
                </td>
                <td class="px-6 whitespace-no-wrap md:py-3">
                  <div class="flex align-middle">
                    {{ item.bonos }} Bono{{ item.bonos > 1 ? "s" : "" }}
                  </div>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap"
                >
                  <div class="text-xs" v-if="item.entregado">
                    <div
                      v-if="item.deliveredData"
                      class="text-gray-600 font-xs font-normal italic"
                    >
                      Entregado por {{ item.deliveredData.by }}
                    </div>
                    <div
                      v-if="item.deliveredData && item.deliveredData.notes"
                      v-tooltip="item.deliveredData.notes"
                      class="text-indigo-500 cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 inline"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      Ver notas
                    </div>
                  </div>
                  <div class="text-xs" v-else-if="item.asignado">
                    <div class="text-gray-600 font-xs font-normal italic">
                      <div>Asignado con compra</div>
                      <div>{{ item.orderIdCode }}</div>
                    </div>
                  </div>
                  <div v-else class="flex justify-end print:hidden">
                    <button
                      v-tooltip="'Archivar'"
                      v-if="!item.entregado"
                      @click="$emit('deliver', item)"
                      class-old="flex items-center px-2 py-1 text-xs font-semibold text-white transition duration-150 ease-in-out bg-green-500 rounded hover:bg-green-700"
                      class="flex items-center text-xs font-semibold text-indigo-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        version="1.1"
                        class="w-3 h-3 mr-1 text-indigo-500 fill-current"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 122.88 106.44"
                        style="enable-background: new 0 0 122.88 106.44"
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            class="st0"
                            d="M41.22,96.1l-1.31-32.97l14.83-0.59c6.33,0.88,12.76,4.04,19.2,7.75l11.52-0.46c5.23,0.11,8.17,5.28,3.24,8.96 c-3.92,3.12-9.26,3.17-14.74,2.89c-3.77-0.04-3.74,5.03,0.19,4.9c1.37,0.05,2.84-0.33,4.14-0.38c6.82-0.28,12.38-1.8,15.6-7.33 l1.57-4.1l16.8-9.18c8.46-3.16,14.16,4.81,8.09,11.3c-12.05,9.52-23.73,18.21-36.51,24.73c-9.23,6.13-18.69,6.31-28.37,1.13 L41.22,96.1L41.22,96.1z M117.26,14.9l0.16,32.03c0.01,1.67-1.05,3.11-2.54,3.72l0,0.01L87.04,61.91 c-0.24,0.04-0.48,0.07-0.73,0.07c-0.54,0-1.07-0.11-1.57-0.32L57.34,51.18l0-0.01c-1.48-0.62-2.52-2.1-2.52-3.83l0.15-31.62 c-0.02-0.34,0-0.68,0.06-1.02c0.01-0.48,0.11-0.96,0.3-1.4c0.43-1.04,1.26-1.81,2.25-2.21l0,0L84.36,0.31 c1.09-0.44,2.26-0.39,3.26,0.04l26.73,9.67c1.6,0.58,2.61,2.02,2.68,3.57C117.18,14.01,117.26,14.45,117.26,14.9L117.26,14.9 L117.26,14.9z M81.06,55.25l0.2-27.9l-21.44-9.27L59.37,46.1L81.06,55.25L81.06,55.25L81.06,55.25z M112.68,18.09l-23.96,9.27 l0.19,27.87l23.91-8.93L112.68,18.09L112.68,18.09L112.68,18.09z M0,61.99l35.32-1.86l1.57,39.45l-35.32,1.85L0,61.99L0,61.99z"
                          />
                        </g>
                      </svg>

                      Entregar
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: () => {
        return null;
      },
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    eventPrice: {
      type: Number,
      default: 0,
    },
    patchPending: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      limit: 20,
      busy: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  bshipment-bottom: 0;
}

th:not(:last-child) {
  bshipment-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.button-w-action {
  min-width: 150px;
}
</style>
